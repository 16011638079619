<template>
<div class="page-wrap">
    <!-- header  -->
    <header class="header-section has-header-main">
        <!-- Header main -->
        <HeaderMain></HeaderMain>
    </header>
    <!-- Register section -->
    <RegisterSection class="main-content"></RegisterSection>
    <!-- Blog  -->
    <Footer classname="bg-cus on-dark"></Footer>
</div><!-- end page-wrap -->
</template>

<script>
// Import component data. You can change the data in the store to reflect in all component
import SectionData from '@/store/store.js'
import HeaderMain from "@/components/n1/HeaderMain"
import RegisterSection from "@/components/n1/RegisterSection"
import Footer from "@/pages/n1/Footer"

export default {
  name: 'Register',
  components:{
    HeaderMain,
    RegisterSection,
    Footer

  },
  data () {
    return {
      SectionData
    }
  },
  created(){
    this.fetchRouteParams();
  },
  methods: {
    fetchRouteParams() {
      const urlParams = new URLSearchParams(window.location.search);
      const utmCampaign = urlParams.get('utm_campaign');
      const utmSource = urlParams.get('utm_source');
      // console.log('utm_campaign:', utmCampaign);
      if(utmCampaign === 'coinband'){
        // console.log('utm_source:', utmSource)
        if(utmSource === 'google'){
          sessionStorage.setItem('source', 'cb-google');
        }
        if(utmSource === 'facebook'){
          sessionStorage.setItem('source', 'cb-facebook');
        }
      }else if(utmSource === 'taboola') {
        sessionStorage.setItem('source', 'taboola');
      }
    },
  }
}
</script>


<style scoped>
.main-content {
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 5px;
  padding-right: 5px;
}
.bg-cus {
  background-color: #ffe5d0;
}
</style>
